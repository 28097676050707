angular
  .module('app.config')
  .run(function ($rootScope, UserService, RuntimeRequirements, ScheduledMaintenance, SPORT_ID) {
    $rootScope.isBannerVisible = function () {
      return (
        this.isLoggedInAsAdmin() ||
        this.isUsingUnsupportedBrowser() ||
        this.isScheduledMaintenanceBannerVisible()
      );
    };

    $rootScope.isLoggedInAsAdmin = function () {
      return UserService.isLoggedInAsAdmin();
    };

    $rootScope.isUsingUnsupportedBrowser = function () {
      return RuntimeRequirements.isRecommendationIgnored;
    };

    $rootScope.isScheduledMaintenanceBannerVisible = function () {
      return ScheduledMaintenance.isScheduledMaintenanceBannerVisible;
    };

    $rootScope.activeSportId = parseInt(localStorage.getItem('scActiveSport')) || SPORT_ID.HOCKEY;

    $rootScope.$on('tagging:enter', function () {
      $rootScope.isInTaggingMode = true;
    });

    $rootScope.$on('tagging:leave', function () {
      $rootScope.isInTaggingMode = false;
    });
  });
