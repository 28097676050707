'use strict';

angular.module('app.config').run(function ($rootScope) {
  $rootScope.search = {
    searchterm: '',
    advanced: false,
    teamClass: 'MEN',
  };

  $rootScope.$on(
    '$stateChangeSuccess',
    function (event, toState, toParams, fromState, _fromParams) {
      if (!fromState.name || !toState.name) {
        return;
      }

      const fromModule = fromState.name.split('.').slice(0, 2).join('.');
      const toModule = toState.name.split('.').slice(0, 2).join('.');

      if (fromModule !== toModule) {
        $rootScope.search.advanced = false;
      }
    },
  );
});
